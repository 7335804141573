* {
  box-sizing: border-box;
}
body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
.ant-tabs .ant-tabs-nav-list .ant-tabs-ink-bar {
  background-color: orange;
}
:where(.css-dev-only-do-not-override-1qhpsh8).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: orange !important;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-switch-checked {
  background-color: #16dbcc !important;
}

.bg-filter {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
